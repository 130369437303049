import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seleccion-horario',
  templateUrl: './seleccion-horario.page.html',
  styleUrls: ['./seleccion-horario.page.scss'],
})
export class SeleccionHorarioPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
