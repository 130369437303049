import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkin-sin',
  templateUrl: './checkin-sin.page.html',
  styleUrls: ['./checkin-sin.page.scss'],
})
export class CheckinSinPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
