/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})



export class AlertService{
    constructor(public alertController: AlertController) { }
    async presentAlertConfirm(header: string, message: string): Promise<boolean> {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
          });
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                handler: () => resolveFunction(false)
              },
              {
                text: 'Si',
                role: 'confirm',
                handler: () => resolveFunction(true)
              },
            ],
          });
        await alert.present();
        return promise;
    }
}
