import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalUnidadPageRoutingModule } from './modal-unidad-routing.module';

import { ModalUnidadPage } from './modal-unidad.page';
import { ComponentsModule } from '../../../components/components.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalUnidadPageRoutingModule,
    ComponentsModule
  ],
  declarations: [ModalUnidadPage]
})
export class ModalUnidadPageModule {}
