import { Component, OnInit } from '@angular/core';
import { InstalacionesService } from '../../../services/instalaciones.service';
import { Instalacion } from '../../../models/Instalacion';
import { Router } from '@angular/router';
@Component({
  selector: 'app-search-reservas-sin',
  templateUrl: './search-reservas-sin.page.html',
  styleUrls: ['./search-reservas-sin.page.scss'],
})
export class SearchReservasSinPage implements OnInit {
  instalacion: Instalacion[];
  instalacionesFilter: any = [];
  search = '';
  constructor(public instalacionesService: InstalacionesService, public router: Router) { }

  async ngOnInit() {
    const valido = await this.instalacionesService.getInstalaciones();
      if(valido){
        //OBTENEMOS EL VALOR DEL EMPRENDIMIENTO
         this.instalacion = await this.instalacionesService.getData();
      }  else {
      }
  }

  async reservar(item: any){
    await this.instalacionesService.setInstalacionId(Number(item.id));
    await this.instalacionesService.setInstalacionPrice(Number(item.price));
    this.router.navigateByUrl('/search-disponibilidad');
  }
}
